import { getAuth } from "firebase/auth";

const getBaseUrl = () => {
  console.log("fetcher");
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://app.klartext.ai/api";
      break;
    default:
      url = "";
      console.log("url", url);
  }

  return url;
};

const fetcher = async (url, options = {}) => {
  //TODO: This initially returns null for user when loading, needs to be fixed
  const jointURL = getBaseUrl() + url;
  const auth = await getAuth();
  const user = await auth.currentUser;
  const token = await user.getIdToken();

  const newOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(jointURL, newOptions);
  return response;
};

export { fetcher };
