import React from "react";
import { colorScheme } from "./Colors";
import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/solid";

const PrimaryButton = ({ children, buttonText, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <p>{buttonText}</p>
      {children}
    </button>
  );
};

const SecondaryButton = ({ children, buttonText, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <p>{buttonText}</p>
      {children}
    </button>
  );
};

const IconButton = ({ children, buttonText, onClick, icon }) => {
  const icons = {
    ThumbDownIcon,
    ThumbUpIcon,
  };
  const IconToRender = icons[icon];
  return (
    <button
      className="flex items-center text-xs mt-2 rounded-full px-1 text-gray-500 bg-gray-100 border border-gray-400"
      onClick={onClick}
    >
      <IconToRender className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
      {buttonText}
    </button>
  );
};

export { PrimaryButton, SecondaryButton, IconButton };
