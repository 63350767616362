import { useState } from "react";
import { Switch } from "@headlessui/react";
import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline";

import { fetcher } from "../api/fetcher";

import ImageCrop from "./ImageCrop";
import { KeyIcon } from "@heroicons/react/solid";
import ButtonBarFurtherSimplification from "./ui/ButtonBarFurtherSimplification";
import { PrimaryButton, SecondaryButton } from "./ui/MainButtons";
import Dropdown from "./basics/Dropdown";

const exampleQuery = `Die Titanic ist gesunken.`;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimplifyTextDialog(props) {
  const [temperature, setTemperature] = useState(40);
  const [inputText, setInputText] = useState(exampleQuery);
  const [outputText, setOutputText] = useState();
  const [previousOutputs, setPreviousOutputs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [selectedCEFRLevel, setSelectedCEFRLevel] = useState("Vereinfacht");

  const handleClickCancel = () => {
    setLoading(false);
    setOutputText(null);
  };
  const handleClickOnSummarizeText = async () => {
    setLoading(true);
    console.log(inputText);
    try {
      console.log("hi");
      console.log(props.uid);
      console.log("querying model with temperature: ", temperature);
      var level_api;
      switch (selectedCEFRLevel) {
        case "Stark vereinfacht":
          level_api = "A2";
          break;
        case "Vereinfacht":
          level_api = "B1";
          break;
        case "Teilweise vereinfacht":
          level_api = "B2";
          break;
        default:
          level_api = "A2";
      }
      const response = await fetcher(`/summarize-text`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          temperature: temperature,
          input_text: inputText,
          uid: props.uid,
          cefr_level: level_api,
        }),
      });
      const summarizedText = await response.json();
      console.log("now comes the response");
      console.log(summarizedText);
      setOutputText(summarizedText);
      setPreviousOutputs([summarizedText, ...previousOutputs]);
      setLoading(false);
    } catch (err) {
      alert(err.message);
    }
  };
  console.log();

  return (
    <div>
      {loading ? (
        <div className="flex flex-col justify-center h-120">
          <div className="flex justify-center text-center">
            <img
              className="h-80"
              src="99297-loading-files.gif"
              alt="not loading"
            />
          </div>
          <div className="flex justify-center text-center">
            <p className=" text-indigo-500 pt-2">Ihr Text wird vereinfacht</p>
          </div>
        </div>
      ) : (
        <div className="mx-auto grid md:grid-cols-2 gap-8">
          <div className="">
            <div className="sm:h-20">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Ausgangstext
                </h3>
              </div>
              <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-1 "
              >
                Kopieren Sie Ihren Text in dieses Feld. Alternativ können Sie
                unterhalb ein Foto Ihres Textes hochladen.
              </label>
            </div>
            <div>
              <div>
                <div>
                  <Dropdown
                    label="Auf welches Niveau vereinfachen?"
                    dropDownOptions={[
                      "Stark vereinfacht",
                      "Vereinfacht",
                      "Teilweise vereinfacht",
                    ]}
                    selectedOption={selectedCEFRLevel}
                    setSelectedOption={setSelectedCEFRLevel}
                  />
                </div>
              </div>
              <div className="mt-2 pt-2 sm:pt-0">
                <div className="">
                  <div className="mt-2 sm:mt-0">
                    <textarea
                      id="textToBeSimplified"
                      name="textToBeSimplified"
                      rows={12}
                      className="max-w-4xl shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                      defaultValue={exampleQuery}
                      value={inputText}
                      onChange={(e) => setInputText(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Texterkennung aus Bild
                    </Switch.Label>
                    <Switch.Description
                      as="span"
                      className="text-sm text-gray-500"
                    >
                      Aktivieren Sie den Schiebeschalter für die Texterkennung
                      aus Fotos (Upload).
                    </Switch.Description>
                  </span>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </Switch.Group>
                {enabled ? (
                  <div className="pt-3">
                    <ImageCrop
                      handleCroppedText={setInputText}
                      setLoading={setLoading}
                    />
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <PrimaryButton
                  buttonText="Text vereinfachen"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickOnSummarizeText();
                  }}
                />
              </div>
            </div>

            <form className="space-y-8 divide-y divide-gray-200"></form>
          </div>
          <div className="">
            <div className="min-h-20">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Vereinfachter Text
              </h3>
              <p className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-1 sm:pb-3">
                Die Vereinfachung erscheint hier. Sollte das Ergebnis nicht
                zufriedenstellend sein, lohnt es sich, es erneut zu versuchen
                durch einen weiteren Klick auf “Text vereinfachen”.
              </p>
            </div>
            {loading || outputText ? (
              <div className="center">
                <div className="flex w-full justify-center">
                  <div className="w-full mt-1 sm:mt-0">
                    {loading ? (
                      <div className="">
                        <img
                          className="h-20 w-20"
                          src="spinner.gif"
                          alt="not loading"
                        />
                        <p className="w-full text-center text-indigo-300 pt-2">
                          Loading
                        </p>
                      </div>
                    ) : null}
                    {outputText && !loading ? (
                      <div className="mx-auto ">
                        {previousOutputs.map((previousResult, i) => {
                          console.log("previous: ", previousResult);

                          return (
                            <div className="mt-2 text-gray-700 bg-gray-50 p-3 rounded-md">
                              <p className="max-w-2xl mt-2 whitespace-pre-line">
                                {previousResult}
                              </p>

                              <ButtonBarFurtherSimplification />
                              {/* <div className="mt-3 flex justify-between">
                              <button className="flex items-center text-xs mt-2 rounded-full px-1 text-gray-500 bg-gray-100 border border-gray-400">
                                <ThumbUpIcon
                                  className="mr-3 flex-shrink-0 h-6 w-6"
                                  aria-hidden="true"
                                />
                                Diesen Text kann ich so verwenden
                              </button>
                              <button className="flex items-center text-xs mt-2 rounded-full p-1 text-gray-500 bg-gray-100 border border-gray-400">
                                <ThumbDownIcon
                                  className="mr-3 flex-shrink-0 h-6 w-6"
                                  aria-hidden="true"
                                />
                                Weitere Änderungen sind nötig
                              </button>
                            </div> */}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}

      <form className="mt-12">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Parameter
              </h3>
              <p className="mt-1 w-full text-sm text-gray-500">
                Die folgenden Parameter werden genutzt, um eine Zusammenfassung
                zu erzeugen. Die voreingestellten Werte sollten bereits zu
                nutzbaren Ergebnissen führen.
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Freiheitsgrad des Models
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="range"
                    name="temperature"
                    id="temperature"
                    min="0"
                    max="100"
                    value={temperature}
                    onChange={(e) => {
                      setTemperature(e.target.value);
                    }}
                    className="appearance-none max-w-lg w-full h-2 p-0 bg-indigo-300 focus:outline-none focus:ring-0 focus:shadow-none"
                  />
                  <div>{temperature}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
