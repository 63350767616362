import { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { fetcher } from "../api/fetcher";
const convert = function (obj) {
  return Object.keys(obj).map((key) => ({
    name: key,
    value: obj[key],
    type: "foo",
  }));
};

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

export default function ImageCrop({ handleCroppedText, setLoading }) {
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [cropper, setCropper] = useState();
  const [imageBlob, setImageBlob] = useState();

  const uploadImageCrop = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    // Load file from DataURLData

    // fetch(cropData)
    //   .then((res) => res.blob())
    //   .then((blob) => setImageBlob(blob));
    console.log("image blob", image);

    const blob = await fetch(cropData).then((it) => it.blob());
    const file = new File([blob], image.split("\\").pop().split("/").pop(), {
      type: "image/jpg",
      lastModified: new Date(),
    });
    formData.append("file", file);
    try {
      const response = await fetcher("/image-ocr", {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      handleCroppedText(await result.text);
    } catch (e) {
      alert("Error uploading image");
      alert(e);

      console.log("Error uploading image");
      console.log(e);
    }
    setCropData(null);
    setLoading(false);
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = (e) => {
    e.preventDefault();
    console.log("now setting crop data");
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const [showCroppedImage, setShowCroppedImage] = useState(false);

  // const handleImageLoad = (e) => {
  //   // setImage(e);
  //   setCurrentImageDimensions({
  //     width: e.currentTarget.width,
  //     height: e.currentTarget.height,
  //   });
  // };

  return (
    <div>
      {/* <input
        id="image_file_ocr"
        type="file"
        accept="image/*"
        onChange={(e) => {
          selectImage(e.target.files[0]);
        }}
      /> */}
      <div>
        {/* {image && ( */}
        <div>
          {/* <ReactCrop crop={crop} onChange={setCrop}>
              <img src={src} onLoad={handleImageLoad} />
            </ReactCrop> */}
          <input type="file" accept="image/*" onChange={onChange} />
          {image && (
            <div>
              <div style={{ width: "100%" }}>
                <br />
                <br />
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  zoomTo={0.2}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              </div>
              <div>
                <div>
                  <button
                    onClick={getCropData}
                    className="mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Crop Image
                  </button>
                  {cropData && (
                    <div className="mt-4">
                      <h1>
                        <span>Crop</span>
                      </h1>
                      <img
                        style={{ width: "100%" }}
                        src={cropData}
                        alt="cropped"
                      />
                    </div>
                  )}
                </div>
              </div>
              <br style={{ clear: "both" }} />
            </div>
          )}
          {/* {showCroppedImage && (
              <div>
                <h3 className="mt-3">Cropped Image </h3>
                <img src={croppedImg} onLoad={handleImageLoad} />
              </div>
            )} */}
          <br />
          <button
            onClick={uploadImageCrop}
            className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Text erkennen
          </button>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
