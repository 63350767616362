import { useState, useEffect, useRef } from "react";

import { fetcher } from "../api/fetcher";

export default function InputHistory({ uid }) {
  console.log("---------user:", uid);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [modelResults, setModelResults] = useState({});
  const fetchInputs = async () => {
    setLoading(true);
    try {
      const response = await fetcher(`/inputs?uid=${uid}`, {
        method: "GET",
      });
      const availableInputs = await response.json();
      console.log(availableInputs);
      setInputs(availableInputs["inputs"]);
      setLoading(false);
      console.log(inputs);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    fetchInputs();
  }, []);

  const fetchModelResults = async (inputId) => {
    try {
      const response = await fetcher(`/summarize-text?inputId=${inputId}`, {
        method: "GET",
      });
      const results = await response.json();
      console.log("results:::::::");
      console.log(results["modelResults"]);
      return await results["modelResults"];
    } catch (err) {
      alert(err.message);
    }
  };
  useEffect(() => {
    let unmounted = false;
    console.log("will fetch inputs");
    inputs.forEach((input) => {
      fetchModelResults(input.id).then((inputResults) => {
        console.log(inputResults);
        if (!unmounted) {
          setModelResults((prevModelResults) => ({
            ...prevModelResults,
            [input.id]: inputResults,
          }));
        }
      });
    });
    return () => {
      unmounted = true;
    };
  }, [inputs]);

  return (
    <div>
      <div>
        {inputs.map((input, index) => (
          <div className="bg-white mb-12 shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Input Nr. {inputs.length - index}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {input.input_text}
              </p>
              <h3 className="text-lg leading-6 font-medium text-gray-900"></h3>
            </div>
            <div className="border-t border-gray-200">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Model Results
                </h3>
                <p>
                  {Object.keys(modelResults).length === inputs.length ? (
                    modelResults[input.id].map((result) => (
                      <p className="bg-gray-100 rounded-md mt-4 mb-4 px-4 py-4">
                        {result.model_response}
                      </p>
                    ))
                  ) : (
                    <p>No model results</p>
                  )}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
