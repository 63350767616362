// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import { fetcher } from "./api/fetcher";
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCvzc2vvav-pGeg98sRZfrvsHIsjtS5ipI",

  authDomain: "no-barriers-e27d6.firebaseapp.com",

  projectId: "no-barriers-e27d6",

  storageBucket: "no-barriers-e27d6.appspot.com",

  messagingSenderId: "1010455874731",

  appId: "1:1010455874731:web:df41db8c4ce0e41a2b3bee",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth();

const localSignInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    // console.log('will create a user')
    console.log("Will create new user");
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await fetcher(`/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: user.uid,
        name: name,
        authProvider: "local",
        email: user.email,
      }),
    });
  } catch (err) {
    alert(err.message);
  }
};

const localSendPasswordResetEmail = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  auth.signOut();
};

export {
  auth,
  localSignInWithEmailAndPassword,
  registerWithEmailAndPassword,
  localSendPasswordResetEmail,
  logout,
};
