import React, { useState } from "react";

import { PrimaryButton, IconButton } from "./MainButtons";

export default function ButtonBarFurtherSimplification() {
  const [useful, setUseful] = useState(null); // null means the user hasn't clicked either button yet
  const [notUsefulReason, setNotUsefulReason] = useState(null); // null means the user hasn't clicked the "not useful" button yet

  const handleUsefulClick = () => {
    setUseful(true); // set useful to true
  };

  const handleNotUsefulClick = () => {
    setUseful(false); // set useful to false
    setNotUsefulReason(null); // reset notUsefulReason in case user had previously selected a reason
  };

  const handleNotUsefulReasonClick = (reason) => {
    setNotUsefulReason(reason); // set the notUsefulReason to the selected reason
  };

  return (
    <div>
      <div className="flex flex-col">
        {useful === null && (
          <div className="flex justify-between space-x-2">
            <IconButton
              buttonText="Diesen Text kann ich so verwenden"
              onClick={handleUsefulClick}
              icon="ThumbUpIcon"
            ></IconButton>
            <IconButton
              buttonText="Ich brauche weitere Änderungen"
              onClick={handleNotUsefulClick}
              icon="ThumbDownIcon"
            ></IconButton>
          </div>
        )}

        {useful === false && notUsefulReason === null && (
          <div className="mt-2 flex space-x-2">
            <PrimaryButton
              onClick={() => handleNotUsefulReasonClick("Content")}
            >
              Noch zu schwierig
            </PrimaryButton>
            <PrimaryButton
              onClick={() => handleNotUsefulReasonClick("Summarization")}
            >
              Inhalt falsch
            </PrimaryButton>
            <PrimaryButton onClick={() => handleNotUsefulReasonClick("Other")}>
              Inhalt unvollständig
            </PrimaryButton>
            <PrimaryButton onClick={handleUsefulClick}>Anderes</PrimaryButton>
          </div>
        )}

        {useful === true && (
          <p className="text-green-500 text-md">Danke für das Feedback!</p>
        )}

        {useful === false && notUsefulReason !== null && (
          <p className="text-red-500 font-bold text-lg">
            The {notUsefulReason} was not satisfying.
          </p>
        )}
      </div>
    </div>
  );
}
